import { render, staticRenderFns } from "./VoucherContent.vue?vue&type=template&id=28bc856f"
import script from "./VoucherContent.vue?vue&type=script&lang=js"
export * from "./VoucherContent.vue?vue&type=script&lang=js"
import style0 from "./VoucherContent.vue?vue&type=style&index=0&id=28bc856f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports